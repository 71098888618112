import React, { useEffect, useState } from 'react';
import { Text,DateField,Link } from '@sitecore-jss/sitecore-jss-react';
import config from '../../../temp/config';
import {
  AdsExpansionPanel,
  AdsLoader,
  AdsTooltip
} from '@arm/arm-design-system-react';
import { UseGlobalState } from '../../../context';
import './ReleaseSection.css';
const EdgeAuth = require('akamai-edgeauth')
const http = require('http') // Module for the test
var isStartDownload = true;
  
// Function just for the simple test
function makeRequest(options, callback) {
  var request = http.request(options, (res) => {
    let data = '';     
    res.on('data', (chunk) => {
        data += chunk;
    });    
    // Ending the response 
    res.on('end', () => {
      callback(res, JSON.parse(data));      
    });      
  })
  request.on('error', (err) => {
      callback(err)
  })
  request.end()
}

const AdsTable = (props) => {
  const [release, setReleaseData] = useState({});        
  const ea = new EdgeAuth({
    key: props.fields.EncryptionKey.value,
    windowSeconds: 100000,
    escapeEarly: true
  }) 
  useEffect(() => {
    getReleaseData();
  }, {}); 
  const  user = UseGlobalState();
  const [userData, setUserData] = useState(user[0].user);  
  useEffect(() => {
    setUserData(user[0]?.user);
  }, user);
  const clickToCopy = (e) => {    
    var copyText = e.target.innerText;
    window.navigator.clipboard.writeText(copyText);
    e.preventDefault();
  };
  const packageDownload = (path, loaderEle) => {
    var downloadUrl = props.fields.PackageDownloadApi.value;
    var packageUrl = downloadUrl?.replace("{file_path}", path);
    if(packageUrl)
    {
      var token = ea.generateURLToken(packageUrl);
      let fileUrl = config.REACT_APP_ORIGIN + `${packageUrl}?__token__=${token}`;
      // Make a request to download the file
      window.open(fileUrl, '_blank');
      loaderEle && loaderEle.classList.add("hide"); 
      let urlParams = new URLSearchParams(window.location.search);
      let params = urlParams.get('params'); 
      if(params !== null)
      {
        let activeTab = params.split(',')[0];
        window.location.href=window.location.href.split('?')[0]+"#"+activeTab;
      }  
    }
       
  }
  
  const clickToDownload = (e,info) => {
    var mustAgree = true;
    var loaderEle = e.currentTarget.getElementsByClassName("download-loader")[0];
    loaderEle && loaderEle.classList.remove("hide");
    if(props.release_item.fields.RequiredAuthentication.value) {
      if(userData?.isAuth){
        let emailId = "";
        let name = "";
        if(userData.user){
          emailId = userData.user.emails;
          name = userData.user.name;
        }
        
        window.docuSignClick.Clickwrap.render({
          environment: props.fields.DocusignEnvironment.value,
          accountId: props.fields.DocusignAccId.value,
          clickwrapId: props.fields.DocusignClickWrapId.value,
          clientUserId: emailId,
          documentData: {
            fullName: name,
            email:  emailId,
            company: '',
            title: '',
            date: ''
          },
          onAgreed() {
            if(mustAgree)
            {
              mustAgree = false;
              packageDownload(info.path, loaderEle);
            }           
          },
          onDeclined() {
            loaderEle && loaderEle.classList.add("hide");
          }
        }, '#ds-clickwrap')
      }
      else{
        window.location.href = config.REACT_APP_ORIGIN + config.REACT_APP_PATHNAME + `user-login${typeof window === "undefined"? "": "?returnUrl="+window.location.pathname+"?params="+window.location.hash.replace("#","")+","+info.path}`;        
      }
    } 
    else{
      packageDownload(info.path, loaderEle);
    }
    
  };  
  async function getReleaseData() {
    
    var akamai_url = props.fields.AkamaiApi.value;

    var new_url = akamai_url?.replace('{product}', props.release_item.fields.Product.value).replace('{release}', props.release_item.fields.ReleaseTitle.value);
    if(new_url){
      var token = ea.generateURLToken(new_url);     
      var options = {
        hostname: config.REACT_APP_EA_HOSTNAME,
        path: `${new_url}?__token__=${token}`
      }
      makeRequest(options, function(res,data) {
        if(res.statusCode === 200)
        {
          setReleaseData(data);
          if(isStartDownload){
            triggerAutoDownload();
          }                  
        }
      });
    }    
  };
  function triggerAutoDownload(){
    let urlParams = new URLSearchParams(window.location.search);
    let params = urlParams.get('params'); 
    if(params !== null){
      let path = params.split(",")[1];
      let downloadEle = document.getElementById(path);
      if(downloadEle !== null)
      {
        isStartDownload = false;
        downloadEle.click();
      }
    }
  }
  function ConvertBytes(bytesVal){
    let value = Number.parseFloat(bytesVal);
    if(!isNaN(value))
    {
      value = (value/1024).toFixed(1);
      return value>=1024 ? (value/1024).toFixed(1) + " MiB" : value + " KiB";   
    }
    return 0.0 + " KiB";
  }  
  return (
      <div class="c-table-wrapper" style={{ paddingBottom: "25px" }}>      
      <table class="c-table is-greyscale is-selectable is-sticky">
      <thead>
        <tr>
          <th scope="col" width="13%"><Text field={props.fields.PlatformText} /></th>
          <th scope="col" width="25%">
            <span class="fal fa-download u-color-arm-black dark:u-color-white u-margin-right-1/2"></span>
            <Text field={props.fields.PackageText} />
          </th>
          <th scope="col" width="16%"><Text field={props.fields.TargetText} /> </th>
          <th scope="col" width="10%"><Text field={props.fields.SizeText} /></th>
          <th scope="col" width="10%"><Text field={props.fields.MD5Text} /></th>
        </tr>
      </thead>
      <tbody>
       {typeof release === 'object' && Object.keys(release).length !== 0 && release.fileInfos.map(info=>(
         <tr style={{ display: info?.hidden ? 'none' : '' }}>
          <th data-title="Platform">{info.platform}</th>
           {info?.hidden ? null : (info?.ispermalink ? (
             <td data-title="Package">
               <span id={info.path} class='arm-pointer dowload-package'>
                 <a class="c-cta-link__anchor" data-track="true" data-track-value={info.name} href={config.REACT_APP_ORIGIN + props.fields.PermanentPackageDownloadApi.value?.replace("{file_path}", info.path)} target="_blank" download>
                   <span class="fal fa-download u-color-arm-blue dark:u-color-arm-light-blue u-margin-right-1/2"></span>{info.name}
                 </a>
                 <AdsLoader className="download-loader hide" />
               </span>
             </td>
           ) : (
             <td data-title="Package">
               <span id={info.path} onClick={(event) => clickToDownload(event, info)} class='arm-pointer dowload-package'>
                 <a class="c-cta-link__anchor" data-track="true" data-track-value={info.name}>
                     <span class="fal fa-download u-color-arm-blue dark:u-color-arm-light-blue u-margin-right-1/2"></span>{info.name}
                     {!userData?.isAuth && (<span class="fal fa-sign-in-alt u-color-arm-blue dark:u-color-arm-light-blue u-margin-left-1/2" title="sign in required"></span>)}
                   </a>
                   <AdsLoader className="download-loader hide" />
                 </span>
               </td>
           ))}
          <td data-title="Target">{info.target}</td>
          <td data-title="Size">{ConvertBytes(info.size)}</td>
          <td data-title="MD5" >
            <span>                    
              <AdsTooltip position="left">
                <div slot="content" onMouseUp={(event)=> clickToCopy(event)}>                          
                {info.md5}               
                </div>
              </AdsTooltip>
            </span>
          </td>
        </tr>
      ))}      
      </tbody>
      </table>
    </div>    
    
  );  
}
const ReleaseSection = ({ fields }) => { 
  const release_list = fields.ReleaseItemsDatasource;
  const latest_release_name = fields.LatestRelease.fields.ReleaseTitle.value;
  return(
    <div className="release-section-widget">      
      <div className='c-row'>      
        <div className='c-col'>
          <h2 className="u-color-inherit u-margin-bottom-1 md:u-margin-bottom-1-1/2  u-font-size-100% sm:u-font-size-200%"><Text field={fields.heading}></Text></h2>
          {release_list.map((release_Item) => 
          <div className='c-col'>

            <AdsExpansionPanel 
              isDefaultExpanded={latest_release_name === release_Item.fields.ReleaseTitle.value}>
              <span slot="toggle">
                <table class="c-table is-transparent" style={{width:"97%"}}>
                  <thead>
                    <tr>
                      <th scope="col" width="25%">
                        <Text field={fields.DownloadsText} /> : <Text field={release_Item.fields.ReleaseTitle} />
                      </th>
                      <th scope="col" width="25%">
                        {
                            new Date(release_Item.fields.ReleaseDate.value).getFullYear() !== 1 &&
                            <DateField field={release_Item.fields.ReleaseDate} render={(date) => date.toLocaleDateString("en-us", { year: 'numeric', month: 'long', day: 'numeric' })} /> 
                        }
                        
                      </th>
                      <th scope="col" width="25%" class='anchor-tag'>
                        {release_Item.fields.ReleaseNoteLink.value.text &&
                        <div>
                          <span class="fal fa-file-alt u-color-arm-blue dark:u-color-arm-light-blue u-margin-right-1/2"></span>
                          {
                            release_Item.fields.ReleaseNoteLink.value.url ? (
                              <>
                                <Link field={release_Item.fields.ReleaseNoteLink} data-track="true" data-track-value={release_Item.fields.ReleaseNoteLink.value.text} className="c-cta-link__anchor" target="_blank"></Link>
                              </>                            
                            ) : (
                              <>
                                <a class="c-cta-link__anchor" data-track="true" data-track-value={release_Item.fields.ReleaseNoteLink.value.text}>{release_Item.fields.ReleaseNoteLink.value.text}</a>
                              </>
                            )
                          }                          
                        </div>}
                      </th> 
                      <th scope="col" width="25%" class='anchor-tag'>
                        {release_Item.fields.ReleaseAdditionalInfo.value.text &&
                          <div>
                            <span class="fal fa-circle-info u-color-arm-blue dark:u-color-arm-light-blue u-margin-right-1/2"></span>
                            {
                              release_Item.fields.ReleaseAdditionalInfo.value.url ? (
                                <>
                                  <Link field={release_Item.fields.ReleaseAdditionalInfo} data-track="true" data-track-value={release_Item.fields.ReleaseAdditionalInfo.value.text} className="c-cta-link__anchor" target="_blank"></Link>
                                </>                            
                              ) : (
                                <>
                                  <a class="c-cta-link__anchor" data-track="true" data-track-value={release_Item.fields.ReleaseAdditionalInfo.value.text}>{release_Item.fields.ReleaseAdditionalInfo.value.text}</a>
                                </>
                              )
                            }                            
                          </div>
                        }
                        
                      </th>                   
                    </tr>
                  </thead>
                </table>              
              </span>
              
              <div slot="content">
                <AdsTable fields={fields} release_item={release_Item}></AdsTable>
              </div>
            </AdsExpansionPanel>
          </div>        
          )}
        </div>
      </div>    
    </div>    
  );
};

export default ReleaseSection;
