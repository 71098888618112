import React from 'react';
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import StarsRating from '../../SharedComponents/StarsRating';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import RenderingStyling from '../../../lib/RenderingStyling';

const TechnicalSpecificationContainerVariantB = ({ fields, rendering, KeyResourcesPlaceholder, CallToActionButtonsPlaceholder, AdditionalResourcesCardPlaceholder }) => (
    <>
        <div  {...RenderingStyling(rendering.componentName)} className="c-row xl:is-collapsed u-padding-top-2-1/2 u-padding-bottom-2-1/2">
            <div className="c-col c-col-12 xl:c-col-8">
                <div>
                    {KeyResourcesPlaceholder}
                    <div className="u-padding-left-1 u-margin-top-2 u-margin-bottom-2 u-flex u-flex-column md:u-flex-row u-align-content-center">
                        {CallToActionButtonsPlaceholder}
                    </div>

                </div>
            </div>
            <div className="c-col c-col-12 xl:c-col-4">
                <StarsRating uid={rendering.uid} />
                {AdditionalResourcesCardPlaceholder}
            </div>
        </div>
        <h2 className="u-margin-top-0" id="specification"><Text field={fields.heading} /></h2>
        <div className="u-padding-left-1 xl:u-padding-right-1">
            <RichText field={fields.summary} />
        </div>
        { fields.table.value!=""?
        <div className="c-row xl:is-collapsed u-padding-bottom-2-1/2 u-padding-left-1 dev-c-tech-specification-table">
            <RichText field={fields.table} />
        </div>
        : <div></div>
         }
    </>
);

export default withPlaceholder(['KeyResourcesPlaceholder', 'CallToActionButtonsPlaceholder', 'AdditionalResourcesCardPlaceholder'])(TechnicalSpecificationContainerVariantB);
