import React from 'react';
import { KeyResources as CEKeyResources } from '@arm/ce-shared-components';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { productHierarchyCoveoQuery } from '../../../services/coveoQueryService';
import config from '../../../temp/config';
import './KeyResources.css';
import RenderingStyling from '../../../lib/RenderingStyling';
import { UseGlobalState } from '../../../context';

const KeyResources = ({ sitecoreContext,rendering }) => {
  const coveoQuery = productHierarchyCoveoQuery(sitecoreContext);
  const [{ user }] = UseGlobalState();
  if (coveoQuery !== "") {
    return (
      <div {...RenderingStyling(rendering.componentName)} className="dev-c-keyResources">
        <CEKeyResources CoveoOrganizationID={`${config.REACT_APP_COVEOORGANIZATIONID}`}
        CoveoGetTokenURI={`${config.REACT_APP_COVEOGETSEARCHTOKENURL}`}
        CoveoSearchHub={`${config.REACT_APP_COVEOPRODUCTPAGESEARCHHUB}`}
        CoveoQueryPipeline={`${config.REACT_APP_COVEOPRODUCTPAGEQUERYPIPELINE}`}
        user={{ token: user.user?.token, isLoggedIn: user.isAuth }}
        tabId={`${config.REACT_APP_COVEOKEYRESOURCESTABID}`}
        coveoQuery={coveoQuery}
        viewMoreLinkUrl={"#Resources"} />
      </div>
      
    );
  }
  else {
    return ""
  }
};

export default withSitecoreContext()(KeyResources);
